import jwt from 'jsonwebtoken';
import autorefresh from 'jwt-autorefresh';
import AbstractService from 'services/AbstractService';
import {
  visitor_tab_routes,
  employee_tab_routes,
  user_roles,
  porter_tab_routes,
  globaladmin_tab_routes,
} from 'AppSettings';
import moment from 'moment-timezone';

class AuthImpl extends AbstractService {
  constructor() {
    super();
    this.history = undefined;
    this.extractToken = (token) => {
      return token.split(' ')[1];
    };

    this.decodeToken = (token) => {
      return jwt.decode(this.extractToken(token));
    };

    /** Function that returns a promise which will resolve to a simple jwt access_token (you handle the persistence mechanism) */
    const refresh = () => {
      // In case computer was sleeping we should check if we are still authenticated (token expired)
      if (!this.isUserSigningTablet()) {
        if (!this.isUserAuthenticated()) {
          return false;
        }
      }
      let self = this;
      return this._refresh(
        this.getUser()._id,
        localStorage.getItem('refresh_token')
      )
        .then(function (newToken) {
          console.log('Got new token');
          return self.extractToken(newToken);
        })
        .catch(function (error) {
          console.log(error);
          self.deauthenticateUser();
        });
    };

    /** You supply a leadSeconds number or function that generates a number of seconds that the refresh should occur prior to the access token expiring */
    const leadSeconds = () => {
      /** Generate random additional seconds (up to 30 in this case) to append to the lead time to ensure multiple clients dont schedule simultaneous refresh */
      const jitter = Math.floor(Math.random() * 30);

      /** Schedule autorefresh to occur 60 to 90 seconds prior to token expiration */
      return 60 + jitter;
    };

    this.start = autorefresh({ refresh, leadSeconds });
    this.cancel = () => {};
  }

  /**
   * SignIn a user.
   *
   * @param {string} username
   * @param {string} password
   */
  signIn(username, password, history) {
    this.history = history;
    var endpoint = 'signin';
    var data = {
      email: username,
      password: password,
    };
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        ._post(endpoint, data)
        .then(function (response) {
          self.authenticateUser(
            response.data.token,
            response.data.refresh_token
          );
          resolve(response.data.token);
        })
        .catch(function (error) {
          reject(self._errorFromErrorResponse(error));
        });
    });
  }

  startTokenRefresh = () => {
    this.cancel();
    try {
      let token = localStorage.getItem('token');
      let exp = this.decodeToken(token).exp;
      if (exp) {
        console.log('Starting token refresh.');
        this.cancel = this.start(this.extractToken(token));
      }
    } catch (error) {
      console.log(error);
    }
  };

  _refresh(user, refresh_token) {
    var endpoint = 'token';
    var data = {
      user: user,
      refresh_token: refresh_token,
    };
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        ._post(endpoint, data)
        .then(function (response) {
          self.authenticateUser(response.data.token);
          resolve(response.data.token);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  /**
   * Enable or disable rememberMe feature
   *
   * @param {boolean} remember - remember?
   */
  setRememberMe(remember) {
    localStorage.setItem('remember_me', remember);
  }

  isRememberMeEnabled() {
    return localStorage.getItem('remember_me');
  }

  rememberMeRestoreSession(callback) {
    const user_id = localStorage.getItem('user_id');
    const refresh_token = localStorage.getItem('refresh_token');
    let self = this;
    if (this.isRememberMeEnabled() && user_id && refresh_token) {
      console.log('rememberMeRestoreSession possible');
      this._refresh(user_id, refresh_token)
        .then(function (newToken) {
          console.log('Got new token', newToken);
          self.authenticateUser(newToken);
          callback(null, true);
        })
        .catch(function (error) {
          console.log(error);
          self.deauthenticateUser();
          callback(error);
        });
    }
  }

  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  authenticateUser(token, refresh_token) {
    localStorage.setItem('token', token);
    if (refresh_token) {
      localStorage.setItem('refresh_token', refresh_token);
    }
    if (this.isRememberMeEnabled()) {
      localStorage.setItem('user_id', this.getUser()._id);
    }
    this.cancel();
    const user = this.decodeToken(token);
    let exp = user.exp;
    if (exp) {
      this.cancel = this.start(this.extractToken(token));
    }

    sessionStorage.setItem('timezone', user.timezone);
    moment.tz.setDefault(user.timezone);

    window.addEventListener('online', this.startTokenRefresh);
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  isUserAuthenticated() {
    let token = localStorage.getItem('token');
    if (token === null) {
      return false;
    }

    // Check expiration if exists
    let decodedToken = this.decodeToken(token);
    let exp = (decodedToken || {}).exp;
    if (this.isUserSigningTablet(true)) {
      return true;
    }
    if (!decodedToken || (exp && exp * 1000 < Date.now())) {
      console.log('Token expired.');
      if (!this.isRememberMeEnabled()) {
        this.deauthenticateUser();
      }
      return false;
    }

    return true;
  }

  /**
   * Check if user is required to agree to terms of use and privacy policy
   *
   * @returns {boolean}
   */
  isAgreeTermsNeeded() {
    if (
      this.isUserAuthenticated() &&
      (this.isUserVisitor() ||
        this.isUserEmployee() ||
        this.isUserAssistant() ||
        this.isUserPorter())
    ) {
      const user = this.getUser();
      return user.agreeTerms === null || user.agreeTerms === undefined;
    }
    return false;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  deauthenticateUser() {
    this.cancel();
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('remember_me');
    sessionStorage.removeItem('timezone');

    window.removeEventListener('online', this.startTokenRefresh);

    if (this.history) this.history.push('/');
  }

  changeTenant(tenantId) {
    this.cancel();

    const endpoint = 'changeTenant';
    const data = {
      user: this.getUser()._id,
      tenantId: tenantId,
    };
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };

    return new Promise((resolve, reject) => {
      this._post(endpoint, data, config)
        .then((response) => {
          this.authenticateUser(response.data.token);
          resolve();
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  isUserVisitor() {
    const user = this.getUser();
    return user.role === user_roles.VISITOR;
  }

  isUserPorter() {
    const user = this.getUser();
    return user.role === user_roles.PORTER;
  }

  isUserMeetingRoom() {
    const user = this.getUser();
    return user.role === user_roles.MEETING_ROOM;
  }

  isUserTurnstile() {
    const user = this.getUser();
    return user.role === user_roles.TURNSTILE;
  }

  isUserEmployee() {
    const user = this.getUser();
    return user.role === user_roles.EMPLOYEE;
  }

  isUserAssistant() {
    const user = this.getUser();
    return user.role === user_roles.ASSISTANT;
  }

  isUserAdmin() {
    const user = this.getUser();
    return (
      user.role === user_roles.ADMIN || user.role === user_roles.GLOBAL_ADMIN
    );
  }

  isUserGlobalAdmin() {
    const user = this.getUser();
    return user.role === user_roles.GLOBAL_ADMIN;
  }

  isUserSigningTablet(refreshing_tablet) {
    const user = this.getUser(refreshing_tablet);
    return user.role === user_roles.SIGNING_TABLET;
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  getToken() {
    return localStorage.getItem('token');
  }

  getAuthToken() {
    return (localStorage.getItem('token') ?? '').replace('JWT ', '');
  }

  /**
   * Get a token payload.
   *
   * @returns {Object}
   */
  getUser(refreshing_tablet) {
    if (refreshing_tablet === true) {
      return this.decodeToken(this.getToken());
    }
    return this.isUserAuthenticated() ? this.decodeToken(this.getToken()) : {};
  }

  getHomePage() {
    if (!this.isUserAuthenticated() || this.isUserSigningTablet()) {
      return '/';
    } else {
      if (this.isUserVisitor()) {
        return visitor_tab_routes[0];
      } else if (this.isUserPorter()) {
        return porter_tab_routes[0];
      } else if (this.isUserGlobalAdmin()) {
        return globaladmin_tab_routes[0];
      } else {
        return employee_tab_routes[0];
      }
    }
  }
}

const Auth = new AuthImpl();

export default Auth;
